/**
 * 启动自动滚动
 */
export const scroll_active = (parentDomQuerySelector, speed = 100) => {
	let parent = document.querySelector(parentDomQuerySelector);
	const scroll_id = "scroll_" + parentDomQuerySelector;
	if (!window.scroll_notice) {
		window.scroll_notice = {};
	}
	if (window.scroll_notice[scroll_id]) {
		clearInterval(window.scroll_notice[scroll_id]);
	}
	window.scroll_notice[scroll_id] = setInterval(function () {
		if (parent.scrollTop >= parent.scrollHeight - parent.offsetHeight) {
			parent.scrollTop = 0;
		} else {
			parent.scrollTop++;
		}
	}, speed);

};

/**
 * 停止自动滚动
 */
export const scroll_stop = (parentDomQuerySelector) => {
	const scroll_id = "scroll_" + parentDomQuerySelector;
	if (window.scroll_notice && window.scroll_notice[scroll_id]) {
		clearInterval(window.scroll_notice[scroll_id]);
	}
};
